import React from "react";

import "./Breakfast.css";
import ListItem from "../../../components/ListItem/ListItem";
import { Text } from "@chakra-ui/react";

const menuDataLeft = [
  {
    title: "Fish Soup",
    description: "Seafood with vegetables",
    price: "25",
  },
  {
    title: "Maroccan Salad",
    description: "Tomato, onion, green pepper",
    price: "20",
  },
  {
    title: "Malva Pazvifloza",
    description: "Lemon, olive oil, malva leaf",
    price: "20",
  },
  {
    title: "Sinew",
    description: "",
    price: "40",
  },
];

const menuDataRight = [
  {
    title: "Green Zukini",
    description:
      "Green Zukini Courgette, cherry tomato, smoked paprika, onion, basil",
    price: "20",
  },
  {
    title: "Pumking- Eggplants",
    description: "Brown rice, pumpkin, aubergine, olive oil, cranberries",
    price: 20,
  },
  {
    title: "Scolymus Hispanicun",
    description: "",
    price: 20,
  },
  ////
  {
    title: "Green Peppers & Tomatoes",
    description: "Tomate, poivron vert, olives",
    price: "20",
  },
  {
    title: "Vegetable Soup",
    description: "Green beans, maize, carrots, potatoes, butternut squash",
    price: 15,
  },
  {
    title: "Weat Soup Harira",
    description: "Sauce tomate, lentilles",
    price: 10,
  },
  {
    title: "Moroccan Soup Haria",
    description: "Sauce tomate, lentilles",
    price: 7,
  },
];

const menuDataLeft2 = [
  {
    title: "Chicken Farmer Tagine",
    description: "Chicken, lemon, olives",
    price: "70",
  },
  {
    title: "Tangiya",
    description: "Lamb, olive oil, garlic",
    price: "60",
  },
  {
    title: "Mrozia",
    description:
      "Sweet and savoury meat, combining a blend of honey spices, cinnamon and almonds",
    price: "60",
  },
];

const menuDataRight2 = [
  {
    title: "Lamb Tagine",
    description: "Lamb shoulder, tomato, lemon",
    price: "60",
  },
  {
    title: "Shrimps Tagine",
    description: "Tomato sauce, prawns",
    price: 60,
  },
  {
    title: "Tuna Tagine",
    description: "Tomato and tuna sauce",
    price: 60,
  },
  ////
  {
    title: "Goat Meat Tagine",
    description: "Goat's meat, onion",
    price: "60",
  },
  {
    title: "Squid Tagine",
    description: "Tomato sauce, squid",
    price: 64,
  },
  {
    title: "Kebab In Sause",
    description: "Kebab, pepper to taste, garlic",
    price: 50,
  },
  {
    title: "Kefta (Mineed Meat)",
    description: "Kebab, pepper to taste, garlic",
    price: 40,
  },
  {
    title: "Meat With Plumps Tagine",
    description: "Chicken, lemon, carrot, olives",
    price: 51,
  },
  {
    title: "Beef Plumps Taginee",
    description: "Tomato, beef, vegetables",
    price: 50,
  },
  {
    title: "Beef Vegetable Taginee",
    description: "Tomato, beef, vegetables",
    price: 50,
  },
  ////
  {
    title: "Chicken With Olives",
    description: "Chicken, olives",
    price: 45,
  },
  {
    title: "Anchovies Tagine",
    description: "Anchois, le persil, l'ail, le poivre noir, le paprika,",
    price: 35,
  },
  {
    title: "Beef Leg With Chichpeas",
    description: "Chickpeas, beef, onion,",
    price: 40,
  },
  {
    title: "Giblets",
    description: "Minced meat, black pepper, vegetables",
    price: 40,
  },
  {
    title: "Brains",
    description: "",
    price: 40,
  },
  {
    title: "Louri",
    description: "",
    price: 40,
  },
  {
    title: "Liver",
    description: "",
    price: 50,
  },
];

const Breakfast = () => {
  return (
    <>
      <div className="container breakfast">
        <Text
          textAlign="center"
          paddingTop="50px"
          fontSize="clamp(1.3rem , 2.3vw, 2.3rem)"
          fontWeight="bold"
        >
          First Course
        </Text>

        <div className="grid-container">
          <div>
            {menuDataLeft.map((data, i) => (
              <ListItem
                title={data.title}
                description={data.description}
                price={data.price}
              />
            ))}
          </div>

          <div>
            {menuDataRight.map((data, i) => (
              <ListItem
                title={data.title}
                description={data.description}
                price={data.price}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="container breakfast">
      <Text
          textAlign="center"
          paddingTop="50px"
          fontSize="clamp(1.3rem , 2.3vw, 2.3rem)"
          fontWeight="bold"
        >
          Main Menu
        </Text>

        <div className="grid-container">
          <div>
            {menuDataLeft2.map((data, i) => (
              <ListItem
                title={data.title}
                description={data.description}
                price={data.price}
              />
            ))}
          </div>

          <div>
            {menuDataRight2.map((data, i) => (
              <ListItem
                title={data.title}
                description={data.description}
                price={data.price}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Breakfast;
