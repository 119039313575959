import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GlobalStyle } from "./globalStyle";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import MenuItem from "./components/Menu/Menu";
import Location from "./components/Location/Location";
import Activity from "./components/activity/Activity";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <GlobalStyle />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/menu" element={<MenuItem />} />
          <Route path="/location" element={<Location />} />
          <Route path="/activity" element={<Activity />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
