import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import { IoRestaurantSharp } from "react-icons/io5";
import { GiFullPizza } from "react-icons/gi";
export const Nav = styled.nav`
  background: transparent;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavLink = styled(Link)`
  color: #fff;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-left: 30px;
  transition: 0.3s ease-out;

  &:hover {
    transition: 0.3s ease-out;
    color: #ffc500;
  }
  @media screen and (max-width: 920px) {
    display: none;
    position: absolute;
    top: 10px;
    left: 25px;
  }
`;

export const NavIcon = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  p {
    transform: translate(-165%, 180%);
    font-size: clamp(0.8rem, 1vw, 1rem);

    @media screen and (max-width: 920px) {
      transform: translate(-125%, 190%);
    }
  }
`;

export const Icon = styled(GiFullPizza)`
  color: #fff;
  font-size: 4rem;

  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-left: 50px;

  img {
    height: 90px;
    @media screen and (max-width: 920px) {
      height: 60px;
    }
  }
`;

export const Bars = styled(IoRestaurantSharp)`
  font-size: clamp(1.6rem, 2vw, 2rem);
  transform: translate(-50%, 20%);

  @media screen and (max-width: 920px) {
    transform: translate(-10%, 30%);
  }
`;
