import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroBtn,
  HeroH1,
  HeroP,
} from "./HeroElement";

import Sidebar from "../Sidebar/Sidebar";

function HeroSection() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <HeroContainer>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <HeroContent>
        <HeroItems>
          <HeroH1>Greatest Tagine ever</HeroH1>
          <HeroP>Ready in 60 seconds</HeroP>
          <HeroBtn>
            <a
              rel="noreferrer"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "#fff",
                alignItems: "center",
                cursor: "pointer",
              }}
              href="https://api.whatsapp.com/send?phone=0658507211"
            >
              Place order
            </a>
          </HeroBtn>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
