import React from "react";
import "./Gallery.css";

import imageItem1 from "../../../images/place1.jpg";
import imageItem2 from "../../../images/food9.jpg";
import imageItem3 from "../../../images/place3.jpg";
import imageItem4 from "../../../images/place4.jpg";
import imageItem5 from "../../../images/food1.jpg";
import imageItem6 from "../../../images/fav1.jpg";
import imageItem7 from "../../../images/location1.jpg";
import imageItem8 from "../../../images/location2.jpg";
import imageItem9 from "../../../images/food10.jpg";
import imageItem10 from "../../../images/food6.jpg";
import { Image } from "@chakra-ui/react";

const Gallery = () => {
  return (
    <div className="gallery">
      <figure className="gallery__item gallery__item-1">
        <Image
          src={imageItem1}
          alt="Gallery image 1"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item-2">
        <Image
          src={imageItem2}
          alt="Gallery image 2"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item-3">
        <Image
          src={imageItem3}
          alt="Gallery image 3"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item-4">
        <Image
          src={imageItem4}
          alt="Gallery image 4"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item-5">
        <Image
          src={imageItem5}
          alt="Gallery image 5"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item-6">
        <Image
          src={imageItem6}
          alt="Gallery image 6"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item-7">
        <Image
          src={imageItem7}
          alt="Gallery image 7"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item-8">
        <Image
          src={imageItem8}
          alt="Gallery image 8"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item-9">
        <Image
          src={imageItem9}
          alt="Gallery image 9"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item-10">
        <Image
          src={imageItem10}
          alt="Gallery image 10"
          className="gallery__img"
        />
      </figure>
    </div>
  );
};

export default Gallery;
