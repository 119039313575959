import React from "react";
import ListItem from "../../../components/ListItem/ListItem";
import "./LunchTime.css";
import { Text } from "@chakra-ui/react";

const menuDataLeft = [
  {
    title: "Pastella Chicken",
    description: "Brick pastry, chicken and honey",
    price: 75,
  },
  {
    title: "Shrimps Omlette",
    description: "Prawn omelette",
    price: 40,
  },
  {
    title: "Spaghetti",
    description: "Spaghetti",
    price: 40,
  },
  {
    title: "Dry Meat",
    description: "",
    price: 40,
  },
  {
    title: "Cheese Omlette",
    description: "Omelette, cheese",
    price: 30,
  },
];

const menuDataRight = [
  {
    title: "Omelette",
    description: "Egg base",
    price: 25,
  },
  {
    title: "Beans Soup - Baisara",
    description:
      "Based on split broad beans, onions, garlic, fresh aromatic herbs and spices",
    price: 20,
  },
  {
    title: "Lentils - Chickpeas",
    description: "Lentils, chickpeas",
    price: 20,
  },
  {
    title: "White - Red Beans",
    description: "",
    price: 20,
  },
  {
    title: "Chicken Briouat",
    description: "Chicken leg, cheese",
    price: 19,
  },
];

const menuDataLeft2 = [
  {
    title: "Lamb Couscous",
    description: "Couscous, lamb, vegetables",
    price: 60,
  },
  {
    title: "Goat Meat Couscous",
    description: "Couscous, goat meat, vegetables",
    price: 60,
  },
  {
    title: "Free - Range Chicken",
    description: "Whole chick",
    price: 70,
  },
];

const menuDataRight2 = [
  {
    title: "Couscous Chicken",
    description: "Couscous, chicken, vegetables",
    price: 50,
  },
  {
    title: "Couscous Meat",
    description: "Couscous, meat, vegetables",
    price: 50,
  },
  {
    title: "Vegetarian Couscous",
    description: "Couscous, vegetables",
    price: 4,
  },
];

const LunchTime = () => {
  return (
    <>
      <div className="section">
        <div className="container lunch-time">
          <Text
            textAlign="center"
            paddingTop="50px"
            fontSize="clamp(1.3rem , 2.3vw, 2.3rem)"
            fontWeight="bold"
          >
            Special Menu
          </Text>

          <div className="grid-container">
            <div>
              {menuDataLeft.map((data, i) => (
                <ListItem
                  title={data.title}
                  description={data.description}
                  price={data.price}
                />
              ))}
            </div>

            <div>
              {menuDataRight.map((data, i) => (
                <ListItem
                  title={data.title}
                  description={data.description}
                  price={data.price}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container lunch-time">
          <Text
            textAlign="center"
            paddingTop="50px"
            fontSize="clamp(1.3rem , 2.3vw, 2.3rem)"
            fontWeight="bold"
            mb='10px'
          >
           Couscous Only On Friday
          </Text>
          <div className="grid-container">
            <div>
              {menuDataLeft2.map((data, i) => (
                <ListItem
                  title={data.title}
                  description={data.description}
                  price={data.price}
                />
              ))}
            </div>

            <div>
              {menuDataRight2.map((data, i) => (
                <ListItem
                  title={data.title}
                  description={data.description}
                  price={data.price}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LunchTime;
