import React from "react";

import "./AboutInfo.css";
import { Text } from "@chakra-ui/react";

const AboutInfo = () => {
  return (
    <>
      <div className="section">
        <div className="container grid-container">
          <div>
            <div className="about-info-content">
              <Text
                
                style={{
                  // fontFamily: "'Open Sans', sans-serif",
                  fontFamily: "'Open Sans', Arial, Tahoma, sans-serif",
                  fontWeight: "bold",
                }}
                color="#000"
                fontSize="clamp(0.9rem , 1vw , 1.1rem)"
                mb="10px"
                textAlign="center"
                letterSpacing="1.3px"
                lineHeight="35px"
              >
                <span style={{ fontSize: "1.7rem", fontWeight: "bold" }}>
                  R
                </span>
                estaurant Bab Ssour is a Moroccan eatery now conveniently located in Center Ville, close to Place Outa El Hammam.<br /> We take pride in offering a diverse selection of authentic Moroccan dishes, including signature Tagines, Couscous, <br />and Kefta, all expertly prepared by skilled chefs. <br />
                <span style={{ fontSize: "1.7rem", fontWeight: "bold" }}>
                  T
                </span>
                he restaurant aims to provide exceptional dining experiences at reasonable prices. 
                With a 4.5-star rating on Google,<br /> our commitment to quality and hospitality is evident. Come and indulge in the vibrant <br /> flavors of Morocco at Restaurant Bab Ssour.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutInfo;
