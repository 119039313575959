import React from "react";
import "./ListItem.css";

const ListItem = ({ title, description, price, itemImage }) => {
  return (
    <div className="list-items">
      <ul>
        <li>
          {itemImage && <img src={itemImage} alt="hero" />}

          <div>
            <h3
              style={{
                marginBottom: "10px",
                marginTop: "20px",
                color: "#ffc500",
                fontSize: "clamp(0.8rem , 1.2vw, 1.2rem)",
              }}
            >
              {title}
            </h3>
            {description && (
              <p style={{ fontSize: "clamp(0.8rem , 1vw, 1rem)" }}>
                {description}
              </p>
            )}
          </div>

          <h3
            style={{ color: "#ffc500", fontSize: "clamp(0.8rem , 1vw, 1rem)" }}
          >
            {price}.00MAD
          </h3>
        </li>
      </ul>
    </div>
  );
};

export default ListItem;
