import { Link } from "react-router-dom";
import styled from "styled-components";

export const ProductsContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #150f0f;
  color: #fff;
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;

export const ProductCard = styled.div`
  margin: 0 2rem;
  line-height: 2;
  width: 300px;
`;

export const ProductImg = styled.img`
  height: 300px;
  min-width: 300px;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 8px 8px #fdc500;
  border-radius: 5px 5px 0px 5px;

`;

export const ProductsHeading = styled.h1`
  font-size: clamp(1.5rem, 2vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
`;

export const ProductTitle = styled.h2`
  font-size: clamp(1rem, 1.3vw, 1.3rem);
  font-weight: bold;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
`;

export const ProductDesc = styled.p`
  margin-bottom: 1rem;
`;

export const ProductPrice = styled.span`
  margin-bottom: 1rem;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
`;

export const ProductButton = styled(Link)`
text-decoration: none;
font-size:clamp(0.9rem , 1vw, 1rem);
  padding: 0.8rem 4rem;
  border: none;
  background-color: #e31837;
  color: #fff;
  transition: 0.2s ease-out;
  &:hover {
    background-color: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;
