import product1 from "../../images/food6.jpg";
import product2 from "../../images/food2.jpg";
import product3 from "../../images/food8.jpg";

export const productData = [
  {
    img: product1,
    alt: "pizza",
    name: "Moroccan Chicken Tajin",
    desc: "Chicken, lemon, olives.",
    price: "70.00MAD",
    button: "View more",
  },
  {
    img: product2,
    alt: "pizza",
    name: "Moroccan Lamb Tajin",
    desc: "Lamb shoulder, tomato, lemon.",
    price: "60.00MAD",
    button: "View more",
  },
  {
    img: product3,
    alt: "pizza",
    name: "Shrimps Tajin",
    desc: "Tomato sauce, shrimp.",
    price: "60.00MAD",
    button: "View more",
  },
];
