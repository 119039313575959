import React, { useState } from "react";
import { AbsoluteCenter, Box, Divider, Heading } from "@chakra-ui/react";
import AboutInfo from "./AboutInfo/AboutInfo";
import { GiPlantRoots } from "react-icons/gi";
import Gallery from "./Gallery/Gallery";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import tagin1 from "../../images/bgb.jpg";
const About = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box w="100%" pos="relative" objectFit="cover" opacity="10" minH="100vh">
      <Box
        backgroundImage={` url(${tagin1})`}
        maxW="100%"
        mx="auto"
        minH="100vh"
        pos="relative"
      >
        <Box
          backgroundImage={` url(${tagin1})`}
          maxW="100%"
          mx="auto"
          minHeight="100vh"
        >
          <Navbar toggle={toggle} />
          <Sidebar isOpen={isOpen} toggle={toggle} />

          <div>
            <Heading textAlign="center" color="#fff" p={45}>
              ABOUT US
            </Heading>
            <Box position="relative" mb="px">
              <Divider borderBottom="2px solid #fff" />
              <AbsoluteCenter
                bg="#150f0f"
                color="#fff"
                px="4"
                fontSize="2.7rem"
              >
                <GiPlantRoots />
              </AbsoluteCenter>
            </Box>
            <AboutInfo />
            <Gallery />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
