import product1 from "../../images/soup1.jpg";
import product2 from "../../images/marsalad.jpg";
import product3 from "../../images/couscous.jpg";

export const productData2 = [
  {
    img: product1,
    alt: "tagine",
    name: "Moroccan Soup Harira",
    desc: "Tomato sauce, lentils.",
    price: "10.00MAD",
    button: "View more",
  },
  {
    img: product2,
    alt: "tagine",
    name: "Maroccan Salad",
    desc: "Tomato, onion, green bell pepper.",
    price: "20.00MAD",
    button: "View more",
  },
  {
    img: product3,
    alt: "Couscous",
    name: "Moroccan Chicken Couscous ",
    desc: "Couscous, chicken, vegetables.",
    price: "50.00MAD",
    button: "View more",
  },
];
