import React from "react";
import {
  SidebarContainer,
  CloseIcon,
  Icon,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap,
} from "./sidebarElement";
import "./sidebar.css";
function Sidebar({ isOpen, toggle }) {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon>
        <CloseIcon />
      </Icon>
      <SidebarMenu>
        <SidebarLink to="/">Home</SidebarLink>
        <SidebarLink to="/about">About</SidebarLink>
        <SidebarLink to="/menu">Menu</SidebarLink>
        <SidebarLink to="/location">Location</SidebarLink>
        <SidebarLink to="/activity">Activity</SidebarLink>

        {/* <SidebarLink to="/">Contact Us</SidebarLink> */}
        <a
          target="_blank"
          rel="noreferrer"
          class="contact-us"
          href="https://api.whatsapp.com/send?phone=0658507211"
        >
          Contact Us
        </a>
      </SidebarMenu>
      <SideBtnWrap>
        <SidebarRoute>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=0658507211"
          >
            Reservation
          </a>
        </SidebarRoute>
      </SideBtnWrap>
    </SidebarContainer>
  );
}

export default Sidebar;
