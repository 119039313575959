import React from "react";
import "./Beverages.css";
import ListItem from "../../../components/ListItem/ListItem";
import { Text } from "@chakra-ui/react";

const menuDataLeft = [
  {
    title: "Big Tea",
    description: "Hot drink",
    price: "38",
  },
  {
    title: "Avocado Juice",
    description: "Fresh juice",
    price: "26",
  },
  {
    title: "Seasonal Juice",
    description: "Fresh juice",
    price: "20",
  },
  {
    title: "Medium Tea",
    description: "Hot drink",
    price: "26",
  },
  {
    title: "Orange Juice",
    description: "Fresh juice",
    price: "15",
  },
  {
    title: "Big Mineral Water",
    description: "Mineral water",
    price: "15",
  },
];

const menuDataRight = [
  {
    title: "Refreshments",
    description: "Fresh juice",

    price: "13",
  },
  {
    title: "Small Tea",
    description: "Hot Drink",
    price: 13,
  },
  {
    title: "Black Coffee",
    description: "Hot Drink",
    price: 13,
  },
  ////
  {
    title: "Coffee With Milk",
    description: "Hot Drink",
    price: "13",
  },
  {
    title: "Small Mineral Water",
    description: "Mineral water",
    price: 10,
  },
];

const menuDataLeft2 = [
  {
    title: "Seasonal Fruits",
    description: "Seasonal Fruits",
    price: "20",
  },
  {
    title: "Yoghurt",
    description: "Yoghurt",
    price: "15",
  },
  {
    title: "Big Gaz Water",
    description: "",
    price: "15",
  },
  {
    title: "Small Gaz Water",
    description: "",
    price: "10",
  },
  {
    title: "Gazelle Horn",
    description: "Gazelle horn",
    price: "07",
  },
  {
    title: "briouat with almonds",
    description: "Hot drink",
    price: "07",
  },
  {
    title: "Orange Juice",
    description: "",
    price: "09",
  },
  {
    title: "Ghorieba",
    description: "Ghorieba",
    price: "05",
  },
];

const Beverages = () => {
  return (
    <>
        <div className="section">
        <div className="container lunch-time">
          <Text
            textAlign="center"
            paddingTop="50px"
            fontSize="clamp(1.3rem , 2.3vw, 2.3rem)"
            fontWeight="bold"
            mb='10px'
          >
           Drinks
          </Text>
          <div className="grid-container">
            <div>
              {menuDataLeft.map((data, i) => (
                <ListItem
                  title={data.title}
                  description={data.description}
                  price={data.price}
                />
              ))}
            </div>

            <div>
              {menuDataRight.map((data, i) => (
                <ListItem
                  title={data.title}
                  description={data.description}
                  price={data.price}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container beverages">
          <Text
            textAlign="center"
            paddingTop="50px"
            fontSize="clamp(1.3rem , 2.3vw, 2.3rem)"
            fontWeight="bold"
            mb='10px'
          >
            Desserts & Moroccan Pastry
          </Text>
          <div className="grid-container">
            <div>
              {menuDataLeft2.map((data, i) => (
                <ListItem
                  key={i}
                  title={data.title}
                  description={data.description}
                  price={data.price}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Beverages;
