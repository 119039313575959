import React, { useState } from "react";
import Breakfast from "./Breakfast/Breakfast";
import LunchTime from "./LunchTime/LunchTime";
import Beverages from "./Beverages/Beverages";
import { GiPlantRoots } from "react-icons/gi";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import tagin1 from "../../images/bgb.jpg";
import { AbsoluteCenter, Box, Divider, Heading } from "@chakra-ui/react";
const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box w="100%" pos="relative" objectFit="cover" opacity="10" minH="100vh">
      <Box
        backgroundImage={` url(${tagin1})`}
        maxW="100%"
        mx="auto"
        minH="100vh"
        pos="relative"
      >
        <Box
          backgroundImage={` url(${tagin1})`}
          maxW="100%"
          mx="auto"
          minHeight="100vh"
          color="#fff"
        >
          <Navbar toggle={toggle} />
          <Sidebar isOpen={isOpen} toggle={toggle} />

          <div>
            <Heading textAlign="center" color="#fff" p={45}>
              OUR MENU
            </Heading>
            <Box position="relative" mb="px">
              <Divider borderBottom="2px solid #fff" />
              <AbsoluteCenter
                bg="#150f0f"
                color="#fff"
                px="4"
                fontSize="2.7rem"
              >
                <GiPlantRoots />
              </AbsoluteCenter>
            </Box>
          </div>

          <Breakfast />
          <LunchTime />
          <Beverages />
        </Box>
      </Box>
    </Box>
  );
};

export default Menu;
